import {createRouter, createWebHistory} from 'vue-router'
import Index from '../views/Index'
import MyList from '../views/MyList'
import Detail from '../views/Detail'
import SearchList from '../views/SearchList'
import MyInfo from '../views/MyInfo';
import Setting from '../views/Setting/Setting';
import Create from '../views/Create';
import Authority from '../views/Authority';
import Privacy from '../views/Privacy';
import TermsOfService from '../views/TermsOfService';

const routes = [
    {
        path: '/',
        name: 'Index',
        // component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
        component: Index,
    },
    {
        path: '/:id',
        name: 'Detail',
        component: Detail,
    },
    {
        path: '/toDetail/:id/:pictureId',
        name: 'toDetail',
        component: Detail,
    },
    {
        path: '/search',
        name: 'search',
        component: SearchList,
    },
    {
        path: '/my',
        name: 'my',
        component: MyInfo,
    },
    {
        path: '/authority',
        name: 'authority',
        component: Authority,
    },
    {
        path: '/mylist',
        name: 'mylist',
        component: MyList,
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
    },
    {
        path: '/create',
        name: 'create',
        component: Create,
    },
    {
        path:'/privacy',
        name:'privacy',
        component:Privacy,
    },
    {
        path:'/termsOfService',
        name:'termsOfService',
        component:TermsOfService,
    }
    
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title ='Look'
    if (to.name === 'create'||to.name === 'my'||to.name === 'setting') {
        if (!localStorage.getItem('token')) {
            router.push({ name: 'Index' })
        } else {
            next();
        }
    }
    next()
})

export default router
